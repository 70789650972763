<template>
  <div ref="hotelCard" class="hotels-info__hotel_card">
    <div class="hotels-info__images" @click="openGallery">
      <Swiper :modules="[Navigation]" navigation>
        <SwiperSlide v-for="(image, index) in hotel.images" :key="image.id">
          <picture class="hotels-info__picture">
            <source :data-srcset="image.desktop.crops[0]" media="(min-width: 768px)" :srcset="image.desktop.crops[0]" />
            <source :data-srcset="image.mobile.crops[0]" :srcset="image.mobile.crops[0]" />
            <img :alt="image.alt" class="hotels-info__image" />
          </picture>
          <div v-if="currentDevice.isMobile && index == hotel.images.length" class="hotels-info__last-slide-cover">
            <div class="hotels-info__last-slide-button">
              <span class="s s-picture-gallery"></span>
              <span>{{ i18n.hotelCard.seePhotosSmall }}</span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="hotels-info__info">
      <div class="hotels-info__info-left">
        <div class="hotels-info__hotel-info">
          <a class="hotels-info__hotel-name" :href="hotel.url">
            <div class="hotels-info__title t-highlight">{{ hotel.title }}</div>
            <div class="hotels-info__stars">
              <span :class="`rating-stars rating-stars--primary r${hotel.hotel_category}`"></span>
            </div>
            <div class="hotels-info__title_hover">
              {{ i18n.hotelCard.viewHotel }}
            </div>
          </a>
          <div class="hotels-info__city t-label">{{ hotel.city }}</div>
          <div
            v-if="hotel.tripadvisor_ranking_data.ranking && hotel.tripadvisor_ranking_data.geo_location_name"
            class="hotels-info__ranking t-fb-label"
          >
            {{ tripadvisorRating }}
          </div>
          <div v-if="!currentDevice.isMobile" class="hotels-info__badges">
            <div v-for="badge in badges" :key="badge.id">
              <div
                v-if="badge.is_star_prestige"
                class="hotels-info__badge badge round uppercase-small is-star-prestige utag-star-prestige-info"
                :data-hotel-id="hotel.id"
                :data-badge-id="badge.badge_id"
                :data-constrained-view-id="constrainedViewId"
              >
                <span>{{ badge.title }}</span>
              </div>
              <Modal v-else :modal-id="`badge-${badge.id}`">
                <template #trigger>
                  <div class="hotels-info__badge badge round uppercase-small uppercase-small">
                    <span>{{ badge.title }}</span>
                  </div>
                </template>
                <template #content>
                  <div class="hotels-info__badge-modal">
                    <div class="hotels-info__badge badge round uppercase-small">{{ badge.title }}</div>
                    <div class="hotels-info__badge-description">{{ badge.description }}</div>
                    <a class="hotels-info__badge-link" :target="badge.target" :href="badge.url">{{
                      badge.link_text
                    }}</a>
                  </div>
                </template>
              </Modal>
            </div>

            <Modal :modal-id="`more-badges-${hotel.id}`">
              <template #trigger>
                <div v-if="showMoreBadges" class="hotels-info__badge badge round uppercase-small">
                  <span>+ {{ hiddenBadges }}</span>
                </div>
              </template>
              <template #content>
                <div v-for="badge in hotel.hotel_badges" :key="badge.id" class="hotels-info__badge-modal">
                  <div class="hotels-info__badge badge round uppercase-small">{{ badge.title }}</div>
                  <div class="hotels-info__badge-description">{{ badge.description }}</div>
                </div>
              </template>
            </Modal>
          </div>
        </div>
        <div class="hotels-info__ratings-links">
          <div
            v-if="
              !currentDevice.isMobile && !hotel.next_opening && hotel.tripadvisor.rating && hotel.tripadvisor.comments
            "
            class="hotels-info__ratings"
          >
            <img :src="hotel.tripadvisor?.rating?.src" :alt="hotel.tripadvisor?.rating?.alt" />
            <span
              class="hotels-info__comments tripadvisor-open-fancy utag-see-hotel-reviews"
              :href="hotel.tripadvisor.comments.src"
            >
              {{ hotel.tripadvisor.comments.title }}
            </span>
          </div>
          <ul v-if="!currentDevice.isMobile" class="hotels-info__info-links">
            <li
              class="hotels-info__info-link utag-info-information-link"
              :data-multi-modal-trigger="hotel.id"
              data-type="hotel"
              :data-lat="hotel.lat"
              :data-lng="hotel.lng"
              :data-zoom="hotel.gmaps_zoom"
              :data-name="hotel.title"
              :data-url="hotel.url"
              data-current-tab="#info-tab"
              @click="handleInfoLinkClick('hotel_services', 'see hotel info', '')"
            >
              <span class="s s-alert-info s-m"></span>
              <span>{{ i18n.hotelCard.infoButton }}</span>
            </li>
            <li
              class="hotels-info__info-link utag-info-photos-link"
              :data-multi-modal-trigger="hotel.id"
              data-type="hotel"
              :data-lat="hotel.lat"
              :data-lng="hotel.lng"
              :data-zoom="hotel.gmaps_zoom"
              :data-name="hotel.title"
              :data-url="hotel.url"
              data-current-tab="#gallery-tab"
              @click="handleInfoLinkClick('hotel_multimedia', 'see photos and videos', 'photos')"
            >
              <span class="s s-picture-gallery s-m"></span>
              <span>{{ i18n.hotelCard.photosButton }}</span>
            </li>
            <li
              class="hotels-info__info-link utag-info-map-link"
              :data-multi-modal-trigger="hotel.id"
              data-type="hotel"
              :data-lat="hotel.lat"
              :data-lng="hotel.lng"
              :data-zoom="hotel.gmaps_zoom"
              :data-name="hotel.title"
              :data-url="hotel.url"
              data-current-tab="#map-tab"
              @click="handleInfoLinkClick('hotel_map', 'see map', '')"
            >
              <span class="s s-city-center s-m"></span>
              <span>{{ i18n.hotelCard.mapButton }}</span>
            </li>
          </ul>
        </div>

        <div v-if="currentDevice.isMobile" class="hotels-info__mobile-info">
          <div
            class="hotels-info__acordion-content"
            :class="{ 'hotels-info__acordion-content--opened': showHotelInfo }"
          >
            <div class="hotels-info__badges">
              <div v-for="badge in badges" :key="badge.id">
                <div
                  v-if="badge.is_star_prestige"
                  class="hotels-info__badge badge is-star-prestige utag-star-prestige-info"
                  :data-hotel-id="hotel.id"
                  :data-badge-id="badge.badge_id"
                  :data-constrained-view-id="constrainedViewId"
                >
                  <span>{{ badge.title }}</span>
                </div>
                <Modal v-else :modal-id="`badge-${badge.id}`">
                  <template #trigger>
                    <div class="hotels-info__badge badge">
                      <span>{{ badge.title }}</span>
                    </div>
                  </template>
                  <template #content>
                    <div class="hotels-info__badge-modal">
                      <div class="hotels-info__badge badge">{{ badge.title }}</div>
                      <div class="hotels-info__badge-description">{{ badge.description }}</div>
                      <a class="hotels-info__badge-link" :target="badge.target" :href="badge.url">{{
                        badge.link_text
                      }}</a>
                    </div>
                  </template>
                </Modal>
              </div>

              <Modal modal-id="more-badges">
                <template #trigger>
                  <div v-if="showMoreBadges" class="hotels-info__badge badge">
                    <span>+ {{ hiddenBadges }}</span>
                  </div>
                </template>
                <template #content>
                  <div v-for="badge in badges" :key="badge.id" class="hotels-info__badge-modal">
                    <div class="hotels-info__badge badge">{{ badge.title }}</div>
                    <div class="hotels-info__badge-description">{{ badge.description }}</div>
                  </div>
                </template>
              </Modal>
            </div>
            <div class="hotels-info__tripadvisor-info">
              <picture class="image hotels-info__tripadvisor-logo">
                <source
                  srcset="https://hotels1.cdn.iberostar.com/uploads/document/document/2052/document.png"
                  media="(min-width: 768px)"
                />
                <source srcset="https://hotels1.cdn.iberostar.com/uploads/document/document/2052/document.png" />
                <img alt="tripadvisor" class="" width="55" height="47" />
              </picture>

              <div class="hotels-info__tripadvisor-content">
                <span class="content-title">
                  <span class="t-fb-label">{{ hotel.tripadvisor.tripadvisor_trip_type_title }}</span>
                </span>
                <span class="content-info t-link-secondary">{{ hotel.tripadvisor.tripadvisor_trip_type_counter }}</span>
              </div>
            </div>
          </div>
          <div class="hotels-info__acordion-trigger">
            <div
              class="hotels-info__acordion-btn"
              :class="{ 'hotels-info__acordion-btn--opened': showHotelInfo }"
              @click="toogleHotelInfo"
            >
              <span>
                {{ showHotelInfo ? i18n.hotelCard.hideHotelInfoMobile : i18n.hotelCard.showHotelInfoMobile }}
              </span>
              <span class="s s-arrow-down s-m"> </span>
            </div>

            <img
              v-if="hotel.tripadvisor?.rating?.src"
              :src="hotel.tripadvisor.rating.src"
              :alt="hotel.tripadvisor.rating.alt"
            />
          </div>
        </div>
      </div>

      <div
        class="hotels-info__info-right"
        :class="{ 'hotels-info__info-right--start': hotel.next_opening || hotel.price_real === null }"
      >
        <div v-if="hotel.next_opening" class="call-to-action" :data-more-info="i18n.hotelCard.moreInfo">
          <div class="new-hotel">
            <div class="new-hotel-box">
              <span class="s s-calendar icon s-2x" aria-label="calendar"></span>
              <p class="ann">{{ i18n.hotelCard.nextOpeningAnnouncement }}</p>
              <p class="desc">
                {{ nextOpeningDate }}
              </p>
            </div>

            <a class="secondary-btn" :href="hotel.url">{{ i18n.hotelCard.visitHotel }}</a>
          </div>
        </div>
        <div
          v-else-if="hotel.price_real === null || hotel.price_real.price_value === null"
          class="call-to-action"
          :data-more-info="i18n.hotelCard.moreInfo"
        >
          <div class="not-available-message">
            <ul>
              <li class="not-available">
                {{ i18n.hotelCard.notAvailableHotelMsg }}
              </li>
              <li class="phone">
                <a href="#phone-list" class="to-phones" data-call-center-link="true">
                  {{ i18n.hotelCard.notAvailableCallMsg }}
                </a>
              </li>
            </ul>
          </div>

          <a
            :href="hotel.urlAvailability"
            class="btn-floating-fastbooking btn-primary availability"
            type="button"
            :data-hotel-id="`h${hotel.id}`"
          >
            {{ i18n.hotelCard.checkAvailability }}
          </a>
        </div>
        <div v-else class="hotels-info__cta">
          <template v-if="hotel.offerInfo">
            <Modal :modal-id="`automatic-offer-${hotel.id}`">
              <template #trigger>
                <div class="special-offer-badge-percent last_minute">
                  <span class="t-fb-label">{{ hotel.offerInfo.automatic_crs_offer_label }}</span>
                  <span class="info-link s s-alert-info" aria-label="Información"></span>
                </div>
              </template>
              <template #content>
                <p>{{ i18n.hotelCard.discountInfo }}</p>
              </template>
            </Modal>
            <p
              class="t-link-secondary special-offer-data-info-link"
              data-hotel_id="3"
              data-stay_from="2024/05/27"
              data-stay_to="2024/06/30"
              data-tooltip="white"
              :data-desc="offerTooltipText"
              data-position="bottom"
            >
              {{ offerText }}
            </p>
          </template>
          <div
            v-if="
              Number(hotel.price_real.base_price_value) > 0 &&
              hotel.price_real.base_price_value !== hotel.price_real.price_value
            "
            class="price-cnt price-before"
          >
            <span class="prev-type">{{ i18n.fastbooking.before }} {{ hotel.textBeforePrice }}</span>
            <span
              class="prev-highlight"
              :data-curr-converter="hotel.price_real.base_price_value ? true : null"
              :data-currency="hotel.price_real.base_price_value ? hotel.price_real.currency : null"
              :data-price="hotel.price_real.base_price_value"
              v-html="hotel.price_real.base_price_with_coin"
            ></span>
          </div>
          <div
            v-if="hotel.price && hotel.price.board && hotel.price.board.show_in_card"
            class="hotels-info__accommodation"
          >
            <span class="s s-reference-restaurant s-s"></span>
            {{ hotel.price.board.description }}
          </div>
          <div v-if="hotel.show_all_inclusive" class="hotels-info__accommodation">
            <span class="s s-reference-restaurant s-s"></span>
            {{ i18n.hotelCard.allInclusive }}
          </div>
          <a
            class="btn-floating-fastbooking btn-primary js-book-btn"
            html="noindex true nofollow true"
            data-curr-converter="inline"
            :href="link_url"
            :data-price="hotel.price_real.price_value"
            :data-base-price="hotel.price_real.base_price_value"
            :data-resident-price="hotel.price_real.resident_price_value"
            :data-currency="hotel.price_real.currency"
            :data-promo-code="hotel.price_real.promocode"
            :data-hotel-id="`h${hotel.id}`"
            :data-hotel-name="hotel.title"
          >
            {{ i18n.fastbooking.enjoyItNow }}
            <span
              class="highlight"
              :data-availability-lightbox="`#availability-dates-${hotel.id}`"
              v-html="hotel.price_real.price_with_coin"
            >
            </span>
          </a>

          <div v-if="isBookingsPage" class="hint-cnt js-hint-cnt t-small-text">
            <span class="hint-cnt__text"> {{ i18n.hotelCard.total_amount }} - {{ i18n.hotelCard.commonHint }}</span>
          </div>
          <div v-else class="hint-cnt js-hint-cnt t-small-text">
            <span class="hint-cnt__text">
              {{ hotel.price_condition ? hotel.price_condition.text : hotel.text_after_price }} -
              {{ i18n.hotelCard.commonHint }}
            </span>
            <Modal :modal-id="`discount-${hotel.id}`">
              <template #trigger>
                <span class="s s-alert-info utag-discount-message"></span>
              </template>
              <template #content>
                <p>
                  {{ hintCtaModalText }}
                </p>
              </template>
            </Modal>
          </div>
        </div>
      </div>
    </div>
    <div class="hotels-info__disclaimer">
      <img v-if="isIHG" class="img-logo" :src="i18n.hotelCard.ihgLogo" alt="ihg logo" />
      <i v-else class="s s-alert-info s-s"></i>
      <span>
        {{ isIHG ? i18n.hotelCard.ihg : i18n.hotelCard.noIhg }}
      </span>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css/navigation'

import dayjs from 'dayjs'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { computed, inject, onMounted, ref, watch } from 'vue'

import { currentDevice } from '../../../../../core/utils/currentDevice'
import { replaceTranslationKeys as t } from '../../../../../utils/replaceTranslationKeys'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'
import Modal from '../../Modal/Modal.vue'
import { emitUtagEvent } from '../HotelsInfo.analytics'

/**
 * Styles
 */
const COMPONENT_NAME = 'HotelCard'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Props
 */
const props = defineProps({
  hotel: {
    type: Object,
    default: () => ({
      next_opening: false,
    }),
  },
})

/**
 * Refs
 */
const hotelCard = ref(null)
const showHotelInfo = ref(false)
const hotel = ref(props.hotel)
const dateFormat = ref('DD/MM/YYYY')

/**
 * Computed
 */
// TODO: REVISAR AUTOMATIC OFFER LIST -> hotel.siteable no existe
const isIHG = computed(() => (hotel.value.siteable ? hotel.value.siteable?.includes('beachfront') : false))
const showMoreBadges = computed(() => hotel.value.hotel_badges.length > 2)
const hiddenBadges = computed(() => hotel.value.hotel_badges.length - 2)
const badges = computed(() => {
  if (hotel.value.hotel_badges.length > 2) return hotel.value.hotel_badges.slice(0, 2)
  return hotel.value.hotel_badges
})
const isBookingsPage = computed(() => document.URL.includes('reservas') || document.URL.includes('bookings'))

/**
 * Inject
 */
const i18n = inject('i18n')
const constrainedViewId = inject('constrainedViewId')

/**
 * Lifecycle
 */
onMounted(() => {
  /**
   * TODO: Don't forceUpdate on every card mount, because it will be changes includes all modules that have prices.
   * Maybe try to update only the price in this module.
   * Need to refactor jQuery forceUpdate method.
   */
  IB.currencyForm.forceUpdate($(hotelCard.value))
  dateFormat.value = document.documentElement.dataset.dateFormat
})

/**
 * Watchers
 */
watch(
  () => hotel.value.price_real?.price_with_coin,
  () => {
    IB.currencyForm.forceUpdate($(hotelCard.value))
  },
  { flush: 'post' }
)

/**
 * Methods
 */
const openGallery = () => {
  // TODO: Implement openGallery
}

const toogleHotelInfo = () => {
  showHotelInfo.value = !showHotelInfo.value
}

const handleInfoLinkClick = (event_name, event_act, event_lbl) => {
  emitUtagEvent({
    event_name,
    event_cat: 'hotel information',
    event_act,
    event_lbl,
    event_purpose: 'inspiration',
    event_structure: 'popup',
  })
}

const offerStart = props.hotel.offerInfo?.offer_start ? dayjs(props.hotel.offerInfo.offer_start) : dayjs()
const offerEnd = props.hotel.offerInfo?.offer_end ? dayjs(props.hotel.offerInfo.offer_end) : offerStart.add(90, 'day')

const offerText = computed(() => {
  let text = i18n.value.hotelCard.travelBetween
  if (props.hotel.offerInfo?.offer_start) {
    text = t(text, {
      stay_from: offerStart.format(dateFormat.value),
      stay_to: offerEnd.format(dateFormat.value),
    })
  } else {
    text = i18n.value.hotelCard.travelUntil
    text = t(text, {
      stay_to: offerEnd.format(dateFormat.value),
    })
  }
  return text
})
const offerTooltipText = computed(() => {
  let text = i18n.value.hotelCard.validStaysFromTo
  if (props.hotel.offerInfo?.offer_start) {
    text = t(text, {
      stay_from: offerStart.format(dateFormat.value),
      stay_to: offerEnd.format(dateFormat.value),
    })
  } else {
    text = i18n.value.hotelCard.validStaysTo
    text = t(text, {
      stay_to: offerEnd.format(dateFormat.value),
    })
  }
  return text
})

const hintCtaModalText = computed(() => {
  let text = i18n.value.hotelCard.offerPriceDatesLegend
  text = text.replace('%{offer_start}', offerStart.format(dateFormat.value))
  text = text.replace('%{offer_end}', offerEnd.format(dateFormat.value))
  return text
})

const tripadvisorRating = computed(() => {
  let text = i18n.value.hotelCard.tripadvisorRating
  text = text.replace('%{ranking}', hotel.value.tripadvisor_ranking_data.ranking)
  text = text.replace('%{place}', hotel.value.tripadvisor_ranking_data.geo_location_name)
  return text
})

const activMyIbeValue = $('.js-myibe-toggle').hasClass('active') ? 1 : 0
const link_url = computed(() => {
  let link = hotel.value.fastbooking_path
  if (link && link.length) {
    link = link + (link.indexOf('?') != -1 ? `&activeMiIB=${activMyIbeValue}` : `activeMiIB=${activMyIbeValue}`)
  }
  return link
})
</script>
